.p-confirm-dialog {
    margin: 10px;
    background-color: rgba(250, 252, 255, 0.95);
}

.p-confirm-dialog div {
    background-color: transparent !important;
}

.confirm-dialog-accept-btn {
    background-color: #1CB49C !important;
    border-color: #1CB49C !important;
}

.confirm-dialog-accept-btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.confirm-dialog-accept-btn:hover {
    background-color: #1CB49C !important;
    border-color: #1CB49C !important;
}

.confirm-dialog-accept-btn:active {
    background-color: #1CB49C !important;
    border-color: #1CB49C !important;
}



.confirm-dialog-reject-btn {
    color: black !important;
    background-color: transparent !important;
    border : none !important;
}

.confirm-dialog-reject-btn:focus {
    outline: none;
    box-shadow: none;
}

.confirm-dialog-reject-btn:hover {
    color: black !important;
    background-color: transparent !important;
    border : none !important;
}

.confirm-dialog-reject-btn:active {
    color: black !important;
    background-color: transparent !important;
    border : none !important;
}


.confirm-dialog-hide-reject .confirm-dialog-reject-btn {
    display: none;
}



