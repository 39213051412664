.home-container {
    background-size: cover;
    background-position-x: right;
    background-position-y: bottom;
    text-align: center;
    height: 100vh;
    padding: 20px;
    position: relative;
    background-image: url(../assets/img/homepage.jpg);
}

.zoom-in-lighthouse {
    transform: translate(-75vw, -45vh) scale(3);
    overflow: hidden;
    transition: all 2s ease-in-out;
}

.zoom-in-lighthouse button {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.home-header {
    margin-bottom: 15vh;
    font-family: "Sabotage";
    font-size: 5vw;
}

.home-header h1 {
    font-size: 10vw;
}

.home-header h5 {
    letter-spacing: 0.5px;
}

.home-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0px 8px;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.home-footer .cas {
    font-size: 14px;
}

.by-support {
    font-family: "Dosis";
    font-size: 14px;
}

.by-support a {
    color: white;
    margin-left: 6px;
}

:root {
    --translate-container-toggler-size: 32px;
    --translate-container-nbr-countries: 3;
    --translate-container-flags-spacing: calc(var(--translate-container-toggler-size) / 3);
    --translate-container-padding: calc(var(--translate-container-toggler-size) / 10);
}

.translate-container {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    right: var(--translate-container-toggler-size);
    width: var(--translate-container-toggler-size);
    border-radius: 0 0 calc(var(--translate-container-toggler-size) / 4) calc(var(--translate-container-toggler-size) / 4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--translate-container-padding);
    gap: var(--translate-container-flags-spacing);
    transform: translateY(calc(-1 * (var(--translate-container-nbr-countries) * (var(--translate-container-toggler-size) + var(--translate-container-flags-spacing))) + (var(--translate-container-toggler-size) / 2)));
}

.translate-container img {
    width: 100%;
    margin: 0 auto;
    display: block;
    margin-top: calc(var(--translate-container-toggler-size) / 10);
}

.translate-container img.flag {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    border-radius: 100%;
}

.home-container.blured {
    filter: blur(2px) brightness(0.5);
    transition: all 0.2s ease-in-out;
}

.home-container.blured button {
    pointer-events: none;
}

.translate-container.displayed {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);
}

.translate-container.hidden {
    transition: all 0.2s ease-in-out;
    transform: translateY(-1000px);
}