.textArea {
  width: 100%;
  resize: none;
  border: none;
  color: black;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0.9;
}

/**
.textArea:focus {
  outline: none !important;
  box-shadow: 0 0 10px #6f6f6f;
}
*/
