.desktop-header {
    margin-bottom: 15vh;
    font-family: "Sabotage";
    font-size: 3vw;
    text-align: center;
}

.desktop-header p {
    font-size: 2vw;
    font-family: "HWTArtz";
}

.desktop-header a {
    color: white;
}


.desktop-header img{
    max-height: 200px;
}