.flip-card {
  --card-width: 80vw;
  --card-ratio: 1.5;
  width: var(--card-width);
  height: calc(var(--card-width) * var(--card-ratio) + 5px);
  perspective: 1000px;
  margin: var(--card-top-margin) auto;
}

.flip-card-container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-style: preserve-3d;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.flip-card-flipped .flip-card-container {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  --card-border-ratio: 13.7;
  padding: calc(var(--card-width) / var(--card-border-ratio) + 2px)
    calc(var(--card-width) / var(--card-border-ratio) + 10px);
}

.flip-card-back {
  background-image: url(../assets/cards/background.png);
}

.flip-card-back > h3 {
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  white-space: pre-wrap;
}

.flip-card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flip-card-back hr,
.flip-card-front hr {
  width: 100px;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flip-card-score {
  text-transform: uppercase;
  width: 100%;
  position: absolute;
  bottom: 5vh;
  left: 0;
}

.flip-card-score img {
  width: auto !important;
}

.flip-card-score h4 {
  font-weight: normal;
  margin: 1vh;
}

.slide.selected .flip-card:not(.flip-card-flipped):not(.no-floating) {
  animation: floating-card 5s ease-in-out infinite;
  animation-delay: 0.5s;
}

.flip-card-review-score {
  width: 100%;
  display: flex;
  vertical-align: top;
  justify-content: center;
  gap: 10px;
}

.flip-card-review-score > img {
  width: 40px !important;
}

.flip-card-review-score > h1 {
  margin: 0;
}

.info-card .flip-card-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flip-card-info-link {
  color: var(--color-primary);
  font-weight: 400;
  font-size: 1.3rem;
  animation: floating-link 5s ease-in-out infinite;
  margin-bottom: 2vh;
}

@keyframes rotate-card {
  0% {
    transform: scale(1, 1) rotateY(0deg);
  }

  20% {
    transform: scale(1.2, 1.2) rotateY(90deg);
  }

  100% {
    transform: scale(1, 1) rotateY(180deg);
  }
}

@keyframes floating-card {
  0% {
    transform: translateY(0);
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.1));
  }

  50% {
    transform: translateY(-15px);
    filter: drop-shadow(0px 12px 17px rgba(255, 255, 255, 0.5));
  }

  100% {
    transform: translateY(0);
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.1));
  }
}

@keyframes floating-link {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
