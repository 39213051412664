input[type="text"] {
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}
