.menu-container {
  background-image: url(../assets/img/lighthouse.jpg);
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  text-align: center;
  height: 100vh;
  padding: 20px;
  position: relative;
}

.menu-container .menu-header {
  margin-top: 30vh;
  margin-bottom: 7vh;
}

.menu-container input[type="text"] {
  margin-bottom: 2vh;
}

.menu-container .read-conditions {
  margin-bottom: 2vh;
  text-decoration: underline;
}

.menu-container .fade-menu-out {
  opacity: 0;
  transition: opacity 0.5s;
}

.menu-container .error {
  color: rgb(255 0 0);
  font-weight: 900;
  background-color: #0000003b;
  margin-top: 0;
  margin-bottom: 12px;
  display : none;
  border-radius: 5px;
}

.menu-container .menu-header h3 {
  font-size: 24px;
  filter: drop-shadow(2px 4px 6px black);
  animation: fadein 2s;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
}

.menu-container .menu-body {
  animation: fadein 2s;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
}

.menu-container.zoom-in-menu {
  animation: zoom-in-door 2s;
}

.menu-container.zoom-in-menu .menu-body *{
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.menu-body .checkbox {
  margin-top: 1vh;
}

.modal-overlay {
  position: fixed;
  inset: 0px;
  backdrop-filter: blur(5px);
}

.modal {
    padding: 20px;
    color: white;
    padding-top: 10vh;
    font-size: 18px;

}

.modal a{
  color : white;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {  /* Safari, Chrome and Opera > 12.1 */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoom-in-door {
  0% {
  }

  90% {
    transform: translateY(-140vh) scale(4);
  }

  100% {
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-19 17:50:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
