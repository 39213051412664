body {
  background: linear-gradient(180deg, #000000 0%, #0a4852 100%) fixed;
}

.game-container {
  animation-name: game-fade-in;
  animation-duration: 4s;
}

.game-card-div {
  padding-right: 8px;
  padding-left: 8px;
  margin-top: calc(var(--card-top-margin) * -1);
  position: relative;
}

.game-body {
  position: absolute;
  width: 75vw;
  bottom: 12px;
  left: calc((100vw - 75vw) / 2);
  text-align: center;
}
.game-body-buttons {
  display: flex;
  column-gap: 10px;
}

.game-body-field-error {
  color: rgb(255 0 0);
  font-weight: 900;
  background-color: #0000003b;
  margin-top: 0;
  margin-bottom: 12px;
  border-radius: 5px;
}

.game-previous-button {
  background-color : transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  color : white;
  height: 100%;
  position: absolute;
  top : 0;
  left : 0;
  z-index : 100;
  width : 40px;
  font-size: 20px;
  font-weight: bold;
}

.game-card-div * {
  overflow: visible;
}

@keyframes game-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}