.audio-player-container {
  --slider-thumb-width: 10px;
  margin-top: 10vh;
  height: 60px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.audio-player-controls {
  width: 10%;
  margin-right: 10px;
}

.audio-player-controls img {
  width: 30px;
  height: 30px;
}

.audio-player-sound-container {
  position: relative;
  width: 100%;
  text-align: center;
}

.audio-player-time {
  text-align: center;
  color: white;
}

.audio-player-marker {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  background-color: #1a7869;
  border-radius: 50%;
  z-index: 10;
}

.audio-player-overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.5);
}

.audio-player-slider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(var(--slider-thumb-width) / 2 * -1);
  -webkit-appearance: none;
  width: calc(100% + var(--slider-thumb-width));
  height: 50px;
  background: transparent;
  outline: none;
  z-index: 10;
  margin: 0;
    padding: 0;
}

.audio-player-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--slider-thumb-width);
  height: var(--slider-thumb-width);
  border-radius: 50%;
  background: #1a7869;
  cursor: pointer;
  translate: -50%, -50%;
}

.audio-player-slider::-moz-range-thumb {
  width: var(--slider-thumb-width);
  height: var(--slider-thumb-width);
  border-radius: 50%;
  background: #1a7869;
  cursor: pointer;
}
