.button {
  background-color: white;
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  font-family: "Dosis";
  font-weight: bold;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  column-gap: 20px;
  display: inline-flex;
  align-items: center; 
  justify-content: center;
}

.button-disabled {
    opacity: 0.8;
    background-color: rgb(196, 196, 196);
    pointer-events: none;
}

.button:active {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.8));
}
