@font-face {
  font-family: "Sabotage";
  src: url("../assets/fonts/Sabotage/sabotage.ttf");
}

@font-face {
  font-family: "HWTArtz";
  src: url("../assets/fonts/HWTArtz/HWTArtz.ttf");
}

@font-face {
  font-family: "Dosis";
  src:
    url("../assets/fonts/Dosis/Dosis-Regular.otf"),
    url("../assets/fonts/Dosis/Dosis-Bold.otf"),
    url("../assets/fonts/Dosis/Dosis-SemiBold.otf"),
    url("../assets/fonts/Dosis/Dosis-Light.otf"),
    url("../assets/fonts/Dosis/Dosis-ExtraLight.otf"),
    url("../assets/fonts/Dosis/Dosis-Medium.otf"),
    url("../assets/fonts/Dosis/Dosis-ExtraBold.otf");
}

:root {
  --card-top-margin: 120px;
}

* {
  box-sizing: border-box;
}

html {
  overflow-y: hidden;
}

body {
  margin: 0;
  font-family: "Dosis";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}