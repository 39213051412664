.header {
  display: flex;
  padding-right: 5vw;
  padding-left: 5vw;
  margin-bottom: 2vh;
  height: 10vh;
}

.header > div {
  flex: 1;
}

.header-left > h2 {
  position: relative;
}

.header-left > h2:before {
  position: absolute;
  height: 1px;
  width: 20%;
  bottom: -10px;
  background: rgb(255, 255, 255);
  content: "";
}

.header-right {
  display: flex;
  gap: 10px;
  justify-content: right;
  align-items: center;
}

.header-right img {
  width: 24px;
  height: 24px;
}

.animate-score h2{
  animation: jello-horizontal 0.9s both;
}

.animate-score img {
  animation: slide-in-elliptic-top-fwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform: translateY(-200px) rotateX(-30deg) scale(0.3);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 300px;
    opacity: 1;
  }
}

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
